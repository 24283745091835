/* eslint-disable no-restricted-imports */
import 'designsystem/src/generated/pillar/institute/tokens.css';
import 'designsystem/src/generated/colorScheme/neutral/tokens.css';
import 'designsystem/src/generated/colorScheme/collection/tokens.css';
import 'designsystem/src/generated/colorScheme/festival/tokens.css';
import 'designsystem/src/generated/colorScheme/institute/tokens.css';
import 'designsystem/src/generated/colorScheme/professionals/tokens.css';
import 'swiper/css';

import fallback from 'designsystem/src/assets/images/placeholder.svg';
/* eslint-enable no-restricted-imports */
import { GlobalStyle, instituteTheme, ThemeProvider, useFontLoaded } from 'designsystem';
import { AppProps } from 'next/app';
import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { FC, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { FormattedMessage } from 'react-intl';
import { NextAdapter } from 'next-query-params';
import { QueryParamProvider } from 'use-query-params';
import InstituteNavigation from '../components/InstituteNavigation';
import { useArticleDetailQuery, useContentPagesQuery, useGlobalsQuery } from '../gql/cms';
import siteHandleByLocale from '../utils/siteHandleByLocale';
import { SessionProvider, signOut, useSession } from 'next-auth/react';
import { DefaultSeo } from 'next-seo';
import { ATProviders, GlobalModals, IdfaIntlProvider, PillarFooter, Trengo, useLastPageCookie } from 'shared';
import NextProgress from 'next-progress';
import { useTheme } from '@emotion/react';
import Script from 'next/script';
import Head from 'next/head';
import { getRedirectSearch } from './[...uri]';

const metadata: Record<string, { title: string; description: string }> = {
    nl: {
        title: 'Ontdek de kracht van creatieve documentaires',
        description:
            'Stap in de wereld van IDFA en ontdek de kracht van creatieve documentaires. Verken wat er te zien is en hoe je mee kunt doen.',
    },
    en: {
        title: 'Discover the Power of Creative Documentaries',
        description:
            "Step into the world of IDFA and discover the power of creative documentary films. Explore what's on and how you can get involved.",
    },
};

const App: FC<AppProps> = props => {
    const [queryClient] = useState(
        () =>
            new QueryClient({
                defaultOptions: {
                    queries: {
                        refetchOnWindowFocus: false,
                        retry: 2,
                        refetchOnMount: false,
                        staleTime: 1000 * 10,
                    },
                },
            })
    );
    const { pageProps } = props;
    const { locale, defaultLocale } = useRouter();
    useLastPageCookie();

    return (
        <QueryParamProvider adapter={NextAdapter}>
            <Head>
                <link rel="icon" href="/favicon.ico" sizes="any" />
            </Head>
            {process.env.NODE_ENV !== 'development' && (
                <>
                    <Script id="google-tag-manager" strategy="afterInteractive">
                        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_CONTAINER_ID}');
      `}
                    </Script>
                    <noscript>
                        <iframe
                            title="gtm-noscript"
                            src={`https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GTM_CONTAINER_ID}}`}
                            height="0"
                            width="0"
                            style={{ display: 'none', visibility: 'hidden' }}
                        />
                    </noscript>
                </>
            )}
            <DefaultSeo
                openGraph={{
                    type: 'website',
                    locale,
                    url: 'https://www.idfa.nl/',
                    siteName: 'IDFA',
                    images: [
                        {
                            url: fallback,
                        },
                    ],
                }}
                additionalMetaTags={[
                    {
                        name: 'author',
                        content: 'Oberon Amsterdam, www.oberon.nl',
                    },
                ]}
                titleTemplate="IDFA Institute | %s"
                twitter={{
                    handle: '@idfa',
                    site: '@idfa',
                    cardType: 'summary_large_image',
                }}
                {...metadata[locale]}
            />
            <SessionProvider session={pageProps.session}>
                <IdfaIntlProvider
                    locale={locale as string}
                    defaultLocale={defaultLocale}
                    messages={pageProps.intlMessages}
                    onError={error => {
                        if (
                            typeof error.message === 'string' &&
                            error.message.includes('[@formatjs/intl Error MISSING_TRANSLATION]')
                        ) {
                            return null;
                        }
                        return error;
                    }}
                >
                    <ThemeProvider theme={instituteTheme}>
                        <QueryClientProvider client={queryClient}>
                            <Hydrate state={pageProps.dehydratedState}>
                                <ATProviders>
                                    <GlobalStyle />
                                    <Main {...props} />
                                    <ReactQueryDevtools initialIsOpen={false} />
                                </ATProviders>
                            </Hydrate>
                        </QueryClientProvider>
                    </ThemeProvider>
                </IdfaIntlProvider>
            </SessionProvider>
        </QueryParamProvider>
    );
};

const Main: FC<AppProps> = props => {
    const { pageProps, Component } = props;
    const session = useSession();
    const graphikLoaded = useFontLoaded('Graphik');
    const { pathname, query, locale, isFallback } = useRouter();
    const uri = [...(query?.uri ?? [])].join('/');
    const isArticle = pathname === '/news/[slug]';
    const isContentPage = pathname === '/[...uri]';
    const site = siteHandleByLocale[locale];
    const { data: contentData } = useContentPagesQuery(
        {
            site,
            uri,
            search: getRedirectSearch(uri),
        },
        { enabled: isContentPage }
    );
    const { data: articleData } = useArticleDetailQuery(
        {
            site,
            slug: query.slug as string,
        },
        { enabled: isArticle }
    );
    const { data, isLoading } = useGlobalsQuery(
        {
            site,
        },
        {
            enabled: !isFallback,
        }
    );

    useEffect(() => {
        if (session.data?.error === 'RefreshAccessTokenError') {
            signOut({ redirect: false });
        }
    }, [session.data?.error]);

    const { tokens } = useTheme();

    return (
        <main
            id="root"
            style={{ minWidth: 'fit-content' }}
            data-font-loaded={graphikLoaded !== null ? graphikLoaded : undefined}
        >
            <NextProgress delay={300} options={{ showSpinner: false }} color={tokens.SyntaxPrimaryColorDarker} />
            <InstituteNavigation isLoading={isLoading} globals={data} content={contentData ?? articleData} />
            <Component {...pageProps} />
            <Trengo />
            <PillarFooter
                isLoading={isLoading}
                globals={data}
                pillarTitle={<FormattedMessage defaultMessage="IDFA Institute" />}
            />
            <GlobalModals />
        </main>
    );
};

export default App;
